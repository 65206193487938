export const HTML_BASE_NAME = `${process.env.PUBLIC_URL.replace(/\/$/, '')}` || '';

export const ROUTES = {
  homepage: {
    name: 'homepage',
    path: '/'
  },
  modelpage: {
    name: 'gama',
    path: '/gama'
  },
  productpage: {
    name: 'model',
    path: '/model'
  }
};
