export default theme => ({
  bannerWrapper: {
    overflow: 'hidden',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  bannerInfoBox: {
    alignItems: 'center',
    background: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      alignItems: 'start',
      padding: theme.spacing(4)
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 10),
      minHeight: '620px',
    }
  },
  bannerHeadline: {
    paddingBottom: theme.spacing(2),
    textTransform: 'uppercase'
  },
  bannerSubheadline: {
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
    fontSize: '1.143em',
    lineHeight: '26px',

    [theme.breakpoints.up('lg')]: {
      lineHeight: '30px'
    }
  },
  bannerButton: {
    width: '238px'
  }
});
