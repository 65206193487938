import daciaBlockBoldEot from './fonts/dacia-block/DaciaBlock-Bold.eot';
import daciaBlockBoldWoff from './fonts/dacia-block/DaciaBlock-Bold.woff';
import daciaBlockLightEot from './fonts/dacia-block/DaciaBlock-Light.eot';
import daciaBlockLightWoff from './fonts/dacia-block/DaciaBlock-Light.woff';
import daciaBlockRegularEot from './fonts/dacia-block/DaciaBlock-Regular.eot';
import daciaBlockRegularWoff from './fonts/dacia-block/DaciaBlock-Regular.woff';
import readBoldWoff from './fonts/read/Read-Bold_V3000.woff';
import readBoldWoff2 from './fonts/read/Read-Bold_V3000.woff2';
import readLightWoff from './fonts/read/Read-Light_V3000.woff';
import readLightWoff2 from './fonts/read/Read-Light_V3000.woff2';
import readMediumWoff from './fonts/read/Read-Medium_V3000.woff';
import readMediumWoff2 from './fonts/read/Read-Medium_V3000.woff2';
import readRegularWoff from './fonts/read/Read-Regular_V3000.woff';
import readRegularWoff2 from './fonts/read/Read-Regular_V3000.woff2';

export const globalTheme = {
  '@global': {
    body: {
      fontSize: 14,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      lineHeight: 'unset'
    },
    'input[type=range]::-webkit-slider-thumb': {
      '-webkit-appearance': 'none',
      '-webkit-transform': 'translateX(-50%)'
    },
    'input[type=range]::-moz-range-thumb': {
      '-webkit-appearance': 'none',
      '-moz-transform': 'translateX(-60%)'
    },
    'input[type=range]::-ms-thumb': {
      '-webkit-appearance': 'none',
      '-ms-transform': 'translateX(-60%)'
    },
    'input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0'
    },
    'input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0'
    },
    'input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    'html, body': {
      margin: 0,
      padding: 0,
      fontFamily: 'Block'
    },
    'body [name="registerInterestForm"] a': {
      color: '#646b52'
    },
    '#root': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    '@font-face': [
      {
        fontFamily: 'Block',
        fontWeight: '500',
        fontStyle: 'normal',
        src: `url(${daciaBlockRegularWoff}) format("woff")`,
        fallbacks: [
          { src: `url(${daciaBlockRegularEot}) format("eot")` }
        ]
      },
      {
        fontFamily: 'Block',
        fontWeight: '700',
        fontStyle: 'normal',
        src: `url(${daciaBlockBoldWoff}) format("woff")`,
        fallbacks: [
          { src: `url(${daciaBlockBoldEot}) format("eot")` }
        ]
      },
      {
        fontFamily: 'Block',
        fontWeight: '400',
        fontStyle: 'normal',
        src: `url(${daciaBlockLightWoff}) format("woff")`,
        fallbacks: [
          { src: `url(${daciaBlockLightEot}) format("eot")` }
        ]
      },
      {
        fontFamily: 'Read',
        fontWeight: '500',
        fontStyle: 'normal',
        fontDisplay: 'auto',
        src: `url(${readRegularWoff}) format("woff")`,
        fallbacks: [
          { src: `url(${readRegularWoff2}) format("woff2")` }
        ]
      },
      {
        fontFamily: 'Read',
        fontWeight: '600',
        fontStyle: 'normal',
        fontDisplay: 'auto',
        src: `url(${readMediumWoff}) format("woff")`,
        fallbacks: [
          { src: `url(${readMediumWoff2}) format("woff2")` }
        ]
      },
      {
        fontFamily: 'Read',
        fontWeight: '400',
        fontStyle: 'normal',
        fontDisplay: 'auto',
        src: `url(${readLightWoff}) format("woff")`,
        fallbacks: [
          { src: `url(${readLightWoff2}) format("woff2")` }
        ]
      },
      {
        fontFamily: 'Read',
        fontWeight: '700',
        fontStyle: 'normal',
        fontDisplay: 'auto',
        src: `url(${readBoldWoff}) format("woff")`,
        fallbacks: [
          { src: `url(${readBoldWoff2}) format("woff2")` }
        ]
      }
    ],
    '#mobile-inner': {
      zIndex: 2
    },
    '.react-transform-wrapper': {
      width: '100% !important'
    },
    '.react-transform-component': {
      width: '100% !important'
    },
    '.rc-slider': {
      position: 'relative',
      height: '14px',
      width: '100%',
      marginTop: 80,
      borderradius: '6px',
      touchAction: 'none',
      boxSizing: 'border-box',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
    },
    '.rc-slider *': {
      boxSizing: 'border-box',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
    },
    '.rc-slider-rail': {
      position: 'relative',
      width: '100%',
      backgroundColor: '#e9e9e9',
      height: '4px',
      borderRadius: '6px'
    },
    '.rc-slider-track': {
      position: 'relative',
      top: '-4px',
      height: '4px',
      borderRadius: '6px'
    },
    '.rc-slider-handle': {
      position: 'absolute',
      width: '14px',
      height: '14px',
      cursor: ['pointer', '-webkit-grab', 'grab'],
      marginTop: '-5px',
      borderRadius: '50%',
      border: 'solid 2px #96dbfa',
      backgroundColor: '#fff',
      touchAction: 'pan-x',
      postion: 'relative',
      zIndex: '1'
      /* transform: 'translateX(0px) !important' */
    },
    '.rc-slider-handle:before': {
      content: `attr(aria-label)`,
      display: 'flex',
      padding: '0',
      minWidth: '82px',
      lineHeight: '1',
      color: '#FFF',
      textAlign: 'center',
      textDecoration: 'none',
      '-webkit-box-shadow': '0 0 4px #d9d9d9',
      boxShadow: '0 0 4px #d9d9d9',
      width: '40px',
      height: '24px!important',
      fontSize: '12px',
      alignItems: 'center',
      fontFamily: 'Block, Arial',
      fontWeight: 700,
      borderRadius: '0!important',
      justifyContent: 'center',
      backgroundColor: '#646b52 !important',
      position: 'relative',
      top: '-38px',
      left: '-36px',
      zIndex: '1'
    },
    '.rc-slider-handle:after': {
      content: `'' !important`,
      display: 'inline-block',
      top: '-14px',
      left: '50%',
      bottom: '-4px !important',
      marginLeft: '-11px !important',
      borderWidth: '8px 8px 0 !important',
      borderTopColor: `#646b52 !important`,
      borderColor: 'transparent',
      position: 'absolute',
      width: '0',
      height: '0',
      borderStyle: 'solid',
      zIndex: '1'
    },
    '.rc-slider-handle.rc-slider-handle:focus': {
      borderColor: '#FFF'
    },
    '.rc-slider-handle-click-focused.rc-slider-handle-click-focused:focus': {
      borderColor: '#FFF'
    },
    '.rc-slider-handle.rc-slider-handle:hover': {
      borderColor: '#FFF'
    },
    '.rc-slider-handle.rc-slider-handle:active': {
      borderColor: '#FFF',
      boxShadow: '0 0 5px #FFF'
    },
    '.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging': {
      borderColor: '#57c5f7',
      boxShadow: '0 0 0 5px #96dbfa'
    },
    '.rc-slider-handle:focus': { outline: 'none' },
    '.rc-slider-handle-click-focused:focus': {
      borderColor: '#96dbfa',
      boxShadow: 'unset'
    },
    '.rc-slider-handle:hover': { borderColor: '#57c5f7' },
    '.rc-slider-handle:active': {
      borderColor: '#57c5f7',
      boxShadow: '0 0 5px #57c5f7',
      cursor: ['-webkit-grabbing', 'grabbing']
    },
    '.rc-slider-mark': {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      top: '-45px'
    },
    '.rc-slider-mark-text': {
      fontFamily: 'Block, Arial',
      fontWeight: '700',
      fontSize: '14px',
      color: '#606164',
      transform: 'translateX(0px) !important'
    },
    '.rc-slider-mark-text-active': { color: '#666' },
    '.rc-slider-step': {
      position: 'absolute',
      width: '100%',
      height: '4px',
      background: 'transparent',
      top: '0px'
    },
    '.rc-slider-dot': {
      position: 'absolute',
      bottom: '-2px',
      marginLeft: '-4px',
      width: '8px',
      height: '8px',
      border: '2px solid #e9e9e9',
      backgroundColor: '#fff',
      cursor: 'pointer',
      borderRadius: '50%',
      verticalAlign: 'middle'
    },
    '.rc-slider-dot-active': { borderColor: '#96dbfa' },
    '.rc-slider-dot-reverse': { marginRight: '-4px' },
    '.rc-slider-disabled': { backgroundColor: '#e9e9e9' },
    '.rc-slider-disabled .rc-slider-track': { backgroundColor: '#ccc' },
    '.rc-slider-disabled .rc-slider-handle,\n.rc-slider-disabled .rc-slider-dot': {
      borderColor: '#ccc',
      boxShadow: 'none',
      backgroundColor: '#fff',
      cursor: 'not-allowed'
    },
    '.rc-slider-disabled .rc-slider-mark-text,\n.rc-slider-disabled .rc-slider-dot': {
      cursor: 'not-allowed !important'
    },
    '.rc-slider-vertical': { width: '14px', height: '100%', padding: '0 5px' },
    '.rc-slider-vertical .rc-slider-rail': { height: '100%', width: '4px' },
    '.rc-slider-vertical .rc-slider-track': {
      left: '5px',
      bottom: '0',
      width: '3px'
    },
    '.rc-slider-vertical .rc-slider-handle': {
      marginLeft: '-5px',
      touchAction: 'pan-y'
    },
    '.rc-slider-vertical .rc-slider-mark': {
      top: '0',
      left: '18px',
      height: '100%'
    },
    '.rc-slider-vertical .rc-slider-step': { height: '100%', width: '4px' },
    '.rc-slider-vertical .rc-slider-dot': { left: '2px', marginBottom: '-4px' },
    '.rc-slider-vertical .rc-slider-dot:first-child': { marginBottom: '-4px' },
    '.rc-slider-vertical .rc-slider-dot:last-child': { marginBottom: '-4px' },
    '.rc-slider-tooltip-zoom-down-enter,\n.rc-slider-tooltip-zoom-down-appear': {
      animationDuration: '0.3s',
      animationFillMode: 'both',
      display: 'block !important',
      animationPlayState: 'paused',
      transform: 'scale(0, 0)',
      animationTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)'
    },
    '.rc-slider-tooltip-zoom-down-leave': {
      animationDuration: '0.3s',
      animationFillMode: 'both',
      display: 'block !important',
      animationPlayState: 'paused',
      animationTimingFunction: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)'
    },
    '.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,\n.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active': {
      animationName: 'rcSliderTooltipZoomDownIn',
      animationPlayState: 'running'
    },
    '.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active': {
      animationName: 'rcSliderTooltipZoomDownOut',
      animationPlayState: 'running'
    },
    '@keyframes rcSliderTooltipZoomDownIn': {
      '0%': { opacity: 0, transformOrigin: '50% 100%', transform: 'scale(0, 0)' },
      '100%': { transformOrigin: '50% 100%', transform: 'scale(1, 1)' }
    },
    '@keyframes rcSliderTooltipZoomDownOut': {
      '0%': { transformOrigin: '50% 100%', transform: 'scale(1, 1)' },
      '100%': {
        opacity: 0,
        transformOrigin: '50% 100%',
        transform: 'scale(0, 0)'
      }
    },
    '.rc-slider-tooltip': {
      position: 'absolute',
      left: '-9999px',
      top: '-9999px',
      visibility: 'visible',
      boxSizing: 'border-box',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
    },
    '.rc-slider-tooltip *': {
      boxSizing: 'border-box',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)'
    },
    '.rc-slider-tooltip-hidden': { display: 'none' },
    '.rc-slider-tooltip-placement-top': { padding: '4px 0 8px 0' },
    '.rc-slider-tooltip-inner': {
      padding: '6px 2px',
      minWidth: '24px',
      height: '24px',
      fontSize: '12px',
      lineHeight: 1,
      color: '#fff',
      textAlign: 'center',
      textDecoration: 'none',
      backgroundColor: '#6c6c6c',
      borderRadius: '6px',
      boxShadow: '0 0 4px #d9d9d9'
    },
    '.rc-slider-tooltip-arrow': {
      position: 'absolute',
      width: '0',
      height: '0',
      borderColor: 'transparent',
      borderStyle: 'solid'
    },
    '.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow': {
      bottom: '4px',
      left: '50%',
      marginLeft: '-4px',
      borderWidth: '4px 4px 0',
      borderTopColor: '#6c6c6c'
    },
    '.rci-one-header-title-wrapper': {
      background: '#fff'
    },
    '.pagination li': {
      display: 'inline-flex',
      border: '1px solid #cccccc',
      height: '46px',
      margin: '0px 16px 16px 0px',
      minWidth: '36px',
      background: '#fff',
      borderRadius: '0px',
      lineHeight: '1.75',
      fontFamily: 'Block, Arial',
      fontWeight: '500',
      cursor: 'pointer',
      position: 'relative',
      top: '8px'
    },
    '.pagination li a': {
      color: 'inherit',
      width: '100%',
      display: 'inherit',
      fontSize: '1em',
      alignItems: 'center',
      fontWeight: '700',
      textTransform: 'uppercase',
      justifyContent: 'center',
      textDecoration: 'none'
    },
    'body #lcv-wrapper': {
      display: 'none'
    },
    '.grey-toolbar': {
      position: 'relative',
      width: '100%'
    },
    '.grey-toolbar-title': {
      top: '0',
      width: '100%',
      padding: '8px',
      fontFamily: 'Read, Arial',
      lineHeight: '15px',
      backgroundColor: '#F2F2F2',
      color: '#000000',
      textAlign: 'center',
      fontSize: '13px',
      margin: '0',
      fontWeight: '400'
    }
  }
};
