export default theme => ({
  selectBtn: {
    display: 'block',
    fontWeight: 'bold',
    height: 48,
    padding: 0,
    position: 'relative',
    width: 220,
    margin: '0 auto',
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.light
    },
    '&$disabledBtn': {
      opacity: 0.5
    }
  },
  selectText: {
    paddingRight: 5
  },
  selectIcon: {
    fill: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    right: 15,
    transform: 'translateY(-50%)'
  },
  smallerBtn: {
    width: 150
  },
  swapText: {
    color: theme.palette.primary.main
  },
  swapBtn: {
    background: theme.palette.common.white,
    border: `2px solid ${theme.palette.secondary.main}`,

    '&:hover': {
      background: theme.palette.common.white,
      border: `2px solid ${theme.palette.primary.main}`
    }
  },
  swapIcon: {
    fill: theme.palette.secondary.main
  },
  backIcon: {
    fill: theme.palette.secondary.main,
    right: 'unset',
    left: 15
  },
  backText: {
    paddingLeft: 10
  },
  linkBtn: {
    width: 200,
    height: 50,
    margin: '10px auto',
    [theme.breakpoints.up('sm')]: {
      width: 220,
      height: 70,
      margin: '20px auto'
    },
    [theme.breakpoints.up('md')]: {
      width: 220,
      height: 70
    }
  },
  disabledBtn: {}
});
