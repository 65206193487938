import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import BannerContainer from './section-banner/section-banner.container.js';
import SearchBoxContainer from './section-search-box/search-box.container.js';

const CarListContainer = lazy(() => import('./section-cars-list/car-list.container.js'));
const MarketingText = lazy(() => import('../../marketing-text/marketing-text.js'));

export class MainPage extends Component {
  static displayName = 'MainPage';

  static propTypes = {
    carSalon: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      bannerChange: false
    };
  }

  handleBannerChange = () => {
    this.setState({ bannerChange: !this.state.bannerChange });
  }

  render = () => {
    const { carSalon, paramsMap, actions, history, location, handleMetaChange } = this.props;

    return (
      <React.Fragment>
        <Suspense fallback={<div style={{ height: '650px' }}></div>}> {carSalon.searchData ? <BannerContainer
          onChange={this.state.bannerChange}
          carModel={carSalon.carModel}
          defaultContent={carSalon.searchData.defaultContent}
          typeContent={carSalon.searchData.types}
          modelContent={carSalon.searchData.models}
          promotionContent={carSalon.searchData.models} /> : <div style={{ height: '650px' }}></div>}
        </Suspense>
        <Suspense fallback={<div style={{ height: '623px', backgroundColor: '#F9F9FB', width: '1280px', margin: '0 auto' }}></div>}> {carSalon.searchData ? <SearchBoxContainer
          handleMetaChange={handleMetaChange}
          handleBannerChange={this.handleBannerChange}
          carModel={carSalon.carModel}
          carSalon={carSalon}
          actions={actions}
          paramsMap={paramsMap}
          history={history}
          location={location}
        /> : <div style={{ height: '623px', backgroundColor: '#F9F9FB', width: '1280px', margin: '0 auto' }}></div>}</Suspense>
        <Suspense fallback={<></>}><CarListContainer
          history={history}
          carSalon={carSalon}
          handleMetaChange={handleMetaChange}
          fetchCarData={actions.fetchCarData}
          changeCarsPerPage={actions.changeCarsPerPage}
          changePageNumber={actions.changePageNumber}
          changeSortParams={actions.changeSortParams} /></Suspense>

        <Suspense fallback={<></>}><MarketingText
          marketingText={carSalon.defaultData.marketingText}
        /></Suspense>

      </React.Fragment>
    );
  };
}

export default (
  MainPage
);
