export default theme => ({
  root: {
    maxWidth: '1280px',
    margin: '0 auto'
  },
  rotateWrapper: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  detailsWrapper: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1)
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: 0
    }
  },
  tabsWrapper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(8),
      padding: 0
    }
  },
  caluclatorWrapper: {
    padding: theme.spacing(1, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 3)
    }
  },
  caluclatorHeadline: {
    fontSize: '2.143em',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.bodyText,
    fontWeight: 400,
    paddingBottom: theme.spacing(6),
    wordBreak: 'break-word',
    [theme.breakpoints.up('xs')]: {
      fontSize: '2.857em'
    }
  },
  calculatorHeadlineHighlighted: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  },
  formWrapper: {
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.background.darker
  },
  goBackWrapper: {
    fontWeight: 700,
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(2, "auto"),
      padding: 0
    }
  },
  goBackButton: {
    padding: 0,
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.common.white
    }
  }
});
