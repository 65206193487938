
const windowUri = window.location.origin;

export const publicUrl = path => `${process.env.PUBLIC_URL || ''}${path}`;
export const API_BASE_URL = process.env.NODE_ENV === 'production' && !process.env.REACT_APP_API_URL ? windowUri : process.env.REACT_APP_API_URL;

// data
export const GET_CARS = `/api/Cars`;
export const GET_DICTIONARIES = `/api/Dictionaries`;
export const GET_DEALERS = `/api/Dealers`;
export const GET_PROVINCES = `/api/Provinces`;
export const GET_IMAGE_URL = `/Image`;
export const GET_360_EXTERNAL = 'https://api.renault-retail-group.fr/images/carousel';

// buttons
export const FACEBOOK = 'https://www.facebook.com/RenaultPolska/';
export const VIDEOCHAT = 'https://videochat.dacia.pl';

// forms
export const SEND_CAR_INTEREST = '/api/Contact';
