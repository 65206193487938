window.dataLayer = window.dataLayer || [];

export const sendEventModelSelect = (model, params) => {
  const { color, fuel, gearbox, version, year, installmentMax, priceMax } = params;
  window.dataLayer.push({
    event: 'znajdź samochód',
    slider1: priceMax == null ? '' : priceMax,
    slider2: installmentMax == null ? '' : installmentMax,
    model: model == null ? '' : model,
    color: color == null ? '' : color,
    fuel: fuel == null ? '' : fuel,
    year: year == null ? '' : year,
    version: version == null ? '' : version,
    gearbox: gearbox == null ? '' : gearbox
  });
};

export const sendForm = () => {
  window.dataLayer.push({
    event: 'send_form_success'
  });
};

export const sendCarDetails = (dealerId, dealerName, vehicleModel, vehicleId) => {
  window.dataLayer.push({
    brand: "dacia",
    countryCode: "PL",
    googleAccount: "UA-23714986-1",
    languageCode: "pl",
    formType: "purchase_request",
    formCategory: "lead_vn",
    formStepNumber: "step-0",
    event: "formValidate",
    pageType: "form",
    businessType: "new-car",
    eventCategory: "Leadform Opening",
    eventAction: "lead_vn",
    eventLabel: "ML-PR",
    dealerName,
    dealerId,
    vehicleModel,
    vehicleId
  });
};

export const leadFormValidation = (dealerName = '', dealerId = '', vehicleModel = '', vehicleId = '', leadId = '') => {
  window.dataLayer.push({
    brand: "dacia",
    countryCode: "PL",
    googleAccount: "UA-23714986-1",
    languageCode: "pl",
    formName:"ML-PR",
    formType: "purchase_request",
    formCategory: "lead_vn",
    formStepNumber: "step-0",
    event: "formValidate",
    pageType: "form",
    businessType: "new-car",
    eventCategory: "Leadform Validation",
    eventAction: "lead_vn",
    eventLabel: "ML-PR",
    dealerName,
    dealerId,
    vehicleModel,
    vehicleId
  });
};
