/**
 * @module @makolab/library-react/build/Loading
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

export class Loading extends Component {
  static displayName = 'Loading';

  static propTypes = {
    classes: PropTypes.object,
    delay: PropTypes.number,
    Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func])
  };

  static defaultProps = {
    classes: {},
    delay: 10,
    Icon: () => (
      <CircularProgress
        variant="indeterminate"
        color="secondary"
      />
    )
  };

  state = {
    showLoading: true
  };

  timeoutHandler = null;

  componentDidMount = () => {
    this.timeoutHandler = setTimeout(() => {
      this.setState({ showLoading: true });
    }, this.props.delay);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeoutHandler);
  };

  render = () => {
    const { Icon } = this.props;

    if (!this.state.showLoading) {
      return null;
    }

    return (
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh', position: 'relative' }}
      >
        <Grid
          item
          xs={12}
          style={{ textAlign: 'center' }}
        >
          <Icon />
        </Grid>
      </Grid>
    );
  };
}

export default Loading;
