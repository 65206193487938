import axios from 'axios';
import * as qs from "qs";

import {
  API_BASE_URL,
  GET_CARS,
  GET_DICTIONARIES,
  GET_DEALERS,
  GET_PROVINCES,
  SEND_CAR_INTEREST
} from './uri';

const CancelToken = axios.CancelToken;

const mainAxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-type': 'application/json' },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

export const sourceCancelTokenGetCarList = CancelToken.source();
export const getCarList = (data) =>
  mainAxiosInstance.request({
    method: 'get',
    url: GET_DICTIONARIES,
    cancelToken: sourceCancelTokenGetCarList.token,
    params: { ...data }
  });

export const sourceCancelTokenGetCarData = CancelToken.source();
export const getCarData = (data) =>
  mainAxiosInstance.request({
    method: 'get',
    url: GET_CARS,
    cancelToken: sourceCancelTokenGetCarData.token,
    params: { ...data }
  });

export const sourceCancelTokenGetDealers = CancelToken.source();
export const getDealers = () =>
  mainAxiosInstance.request({
    method: 'get',
    url: GET_DEALERS,
    cancelToken: sourceCancelTokenGetDealers.token,
  });

export const sourceCancelTokenGetProvinces = CancelToken.source();
export const getProvinces = () =>
  mainAxiosInstance.request({
    method: 'get',
    url: GET_PROVINCES,
    cancelToken: sourceCancelTokenGetProvinces.token,
  });

export const sourceCancelTokenCarInterestLead = CancelToken.source();
export const sendCarInterestLead = data =>
  mainAxiosInstance.request({
    method: 'post',
    url: SEND_CAR_INTEREST,
    cancelToken: sourceCancelTokenCarInterestLead.token,
    data
  });
