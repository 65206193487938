import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core/styles';
import theme from '../../styles/theme-dacia';
import StarterPageContainer from '../starter-page/starter-page.container';
import { Provider } from 'react-redux';
import store from '../../redux-store';
import CssBaseline from '@material-ui/core/CssBaseline';
import {createBrowserHistory} from 'history';

function App() {
  const generateClassName = createGenerateClassName({
    disableGlobal: true,
    seed: 'app',
    productionPrefix: 'class-'
  });

  return (
    <Provider store={store}>
      <StylesProvider injectFirst generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <StarterPageContainer history={createBrowserHistory()}/>
          </Router>
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  );
}

export default App;
