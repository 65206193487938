import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import carSalon from './virtual-salon.reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    carSalon
  });
