import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import flow from 'lodash/flow';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import styles from './button.jss';
import { Button } from '@material-ui/core';

export class ButtonSelect extends Component {
  static propTypes = {
    classes: PropTypes.object,
    label: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    variant: PropTypes.string,
    small: PropTypes.bool,
    swapColor: PropTypes.bool,
    width: PropTypes.number,
    widthPercent: PropTypes.string,
    disabled: PropTypes.bool,
    backBtn: PropTypes.bool,
    submitBtn: PropTypes.bool,
    bigger: PropTypes.bool
  };

  static defaultProps = {
    onClick: () => { },
    color: 'primary',
    type: 'button',
    small: false,
    variant: 'extended',
    swapColor: false,
    disabled: false,
    backBtn: false,
    submitBtn: false,
    bigger: false
  };

  render() {
    const {
      classes,
      color,
      backBtn,
      submitBtn,
      label,
      onClick,
      type,
      variant,
      swapColor,
      width,
      widthPercent,
      disabled
    } = this.props;

    const customWidth = Number.isInteger(width) ? `${width}px` : (typeof widthPercent === 'string' ? widthPercent : 'auto');

    return (
      <Button
        color={color}
        variant={variant}
        type={type}
        aria-label={label}
        disabled={disabled}
        onClick={onClick}
        style={{ width: customWidth }}
      >
        {backBtn && (
          <KeyboardArrowLeft
            className={cx(classes.selectIcon, {
              [classes.swapIcon]: swapColor,
              [classes.backIcon]: backBtn
            })}
          />
        )}
        <span
          className={cx(classes.selectText, {
            [classes.swapText]: swapColor,
            [classes.backText]: backBtn
          })}
        >
          {label}
        </span>
        {!disabled && submitBtn && (
          <KeyboardArrowRight
            className={cx(classes.selectIcon, {
              [classes.swapIcon]: swapColor
            })}
          />
        )}
      </Button>
    );
  }
}

export default flow([withStyles(styles)])(ButtonSelect);
