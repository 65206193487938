import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import cx from 'classnames';
import flow from 'lodash/flow';
import Grid from '@material-ui/core/Grid';
import Button from '../_shared/button/button';
import Typography from '@material-ui/core/Typography';

import {API_BASE_URL} from '../../commons/uri';

import styles from './not-found.jss.js';
import {buttons, caption} from './not-found.data';

export class NotFound extends Component {
  static displayName = 'NotFound';

  static propTypes = {
    classes: PropTypes.object,
    carSalon: PropTypes.object.isRequired,
    fetchCarData: PropTypes.func,
    fetchSearchData: PropTypes.func
  };

  static defaultProps = {
    classes: {}
  };

    takeAction = url => () => {
      window.location.href = url;
    };

  get404Image = () => {
    return `${API_BASE_URL}/Dacia/Banners/404/error.jpg`;
  }

  render = () => {
    const {classes} = this.props;

    return (
      <Grid
        container
        justifyContent="center"
        align="center"
      >

        <Grid
          item
        >
          <img alt="404" className={cx(classes.image)} src={this.get404Image()} />
        </Grid>

        <Grid
          item
          className={cx(classes.wrapper)}
        >
          <Typography
            variant="body1"
            align="center"
            gutterBottom
          >
            <span>{caption}</span>
          </Typography>
          {buttons.map(({name, url, color}) => (
            <Grid item><Button
              key={name}
              label={name}
              variant="contained"
              color={color}
              onClick={this.takeAction(url)}
            /></Grid>
          ))}
        </Grid>
      </Grid>
    );
  };
}

export default flow([withStyles(styles)])(
  NotFound
);
