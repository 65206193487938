/* eslint-disable compat/compat */
import handleError from '../commons/handle-error';

/* CAR DATA */
export const FETCH_DEFAULT_DATA = 'FETCH_DEFAULT_DATA';
export const FETCH_DEFAULT_DATA_SUCCESS = 'FETCH_DEFAULT_DATA_SUCCESS';
export const FETCH_DEFAULT_DATA_ERROR = 'FETCH_DEFAULT_DATA_ERROR';
export const FETCH_FILTER_DATA = 'FETCH_FILTER_DATA';
export const FETCH_FILTER_DATA_SUCCESS = 'FETCH_FILTER_DATA_SUCCESS';
export const FETCH_FILTER_DATA_ERROR = 'FETCH_FILTER_DATA_ERROR';
export const FETCH_CAR_DATA = 'FETCH_CAR_DATA';
export const FETCH_CAR_DATA_SUCCESS = 'FETCH_CAR_DATA_SUCCESS';
export const FETCH_CAR_DATA_ERROR = 'FETCH_CAR_DATA_ERROR';
export const GET_LINK_MAP = 'GET_LINK_MAP';

export function fetchDefaultData() {
  return (dispatch, getState, { axiosInstances }) => {
    return axiosInstances
      .getCarList()
      .then(res => {
        const { status, data } = res;
        if (status === 200) {
          dispatch(getLinkMap(data.mapLinks));
          dispatch(getDefaultDataSucess(data));
        } else {
          throw new Error(res.data.ErrorMessage);
        }
        return data;
      })
      .catch(err => {
        dispatch(getDefaultDataError(err));
      });
  };
}

export function getDefaultDataSucess(payload) {
  return {
    type: FETCH_DEFAULT_DATA_SUCCESS,
    payload
  };
}

export function getDefaultDataError(payload) {
  handleError(payload);

  return {
    type: FETCH_DEFAULT_DATA_ERROR,
    payload
  };
}

export function getLinkMap(payload) {
  return {
    type: GET_LINK_MAP,
    payload
  };
}

export function fetchSearchData(payload) {
  return (dispatch, getState, { axiosInstances }) => {
    const { carModel } = getState().carSalon;
    const { installmentMax, priceMax, promotions, hasPromotion } = getState().carSalon.carParams;
    const { color, fuel, gearbox, version, year, province, dealerId, place } = payload ? payload : getState().carSalon.carParams;
    if (promotions) {
      dispatch(selectPromotions({ hasPromotion: true }));
    }
    return axiosInstances
      .getCarList({
        ...(carModel && { model: carModel }),
        priceMax,
        installmentMax,
        color,
        fuel,
        gearbox,
        version,
        year,
        province,
        ...(dealerId && { dealerId }),
        promotion: promotions,
        hasPromotion,
        ...(place && {
          latitude: place.latitude,
          longitude: place.longitude
        })
      })
      .then(res => {
        const { status, data } = res;
        if (status === 200) {
          dispatch(getFilterDataSuccess(data));
        } else {
          throw new Error(res.data.ErrorMessage);
        }
        return data;
      })
      .catch(err => {
        dispatch(getFilterDataError(err));
      });
  };
}

export function getFilterDataSuccess(payload) {
  return {
    type: FETCH_FILTER_DATA_SUCCESS,
    payload
  };
}

export function getFilterDataError(payload) {
  handleError(payload);

  return {
    type: FETCH_FILTER_DATA_ERROR,
    payload
  };
}

export function fetchCarData(payload) {
  return (dispatch, getState, { axiosInstances }) => {
    const { carModel } = getState().carSalon;
    const { color,
      fuel,
      gearbox,
      installmentMax,
      installmentMin,
      priceMax,
      priceMin,
      version,
      year,
      promotions,
      province,
      dealerId,
      hasPromotion,
      place } = getState().carSalon.carParams;
    const pageNumber = payload ? payload : getState().carSalon.pageNumber;
    const { carsPerPage } = getState().carSalon;
    const { sort, sortDirection } = getState().carSalon.sortParams;
    const fromParams = typeof dealerId === 'string';

    return axiosInstances
      .getCarData({
        ...(carModel && { model: carModel }),
        color,
        fuel,
        gearbox,
        installmentMax,
        installmentMin,
        priceMax,
        priceMin,
        version,
        year,
        province,
        dealerId,
        hasPromotion,
        promotion: promotions,
        perPageParamName: carsPerPage,
        pageNumberParamName: pageNumber,
        sort,
        sortDirection,
        ...(place && {
          latitude: place.latitude,
          longitude: place.longitude
        }),
        ...(dealerId && (fromParams ? { dealerId: dealerId.split(',') } : { dealerId }))
      })
      .then(res => {
        const { status, data } = res;
        if (status === 200) {
          dispatch(fetchCarDataSuccess(data));
        } else {
          throw new Error(res.data.ErrorMessage);
        }

        return data;
      })

      .catch(err => {
        dispatch(fetchCarDataError(err));
      });
  };
}

export function fetchCarDataSuccess(payload) {
  return {
    type: FETCH_CAR_DATA_SUCCESS,
    payload
  };
}

export function fetchCarDataError(payload) {
  handleError(payload);

  return {
    type: FETCH_CAR_DATA_ERROR,
    payload
  };
}

/* PAGINATION */

export const CHANGE_PAGE_NUMBER = 'CHANGE_PAGE_NUMBER';
export const CHANGE_CARS_PER_PAGE = 'CHANGE_CARS_PER_PAGE';
export const CHANGE_SORT_PARAMS = 'CHANGE_SORT_PARAMS';

export function changePageNumber(payload) {
  return {
    type: CHANGE_PAGE_NUMBER,
    payload
  };
}

export function changeCarsPerPage(payload) {
  return {
    type: CHANGE_CARS_PER_PAGE,
    payload
  };
}

export function changeSortParams(payload) {
  return {
    type: CHANGE_SORT_PARAMS,
    payload
  };
}

/* PARAMS */
export const GET_CAR_MODEL = 'GET_CAR_MODEL';
export const GET_CAR_PARAMS = 'GET_CAR_PARAMS';
export const FETCH_CAR_PARAMS_SUCCESS = 'FETCH_CAR_PARAMS_SUCCESS';
export const FETCH_CAR_PARAMS_ERROR = 'FETCH_CAR_PARAMS_ERROR';
export const CLEAR_PARAMS = 'CLEAR_PARAMS';
export const CLEAR_PARAM = 'CLEAR_PARAM';
export const GET_SELECTED_PARAM = 'GET_SELECTED_PARAM';
export const GET_CAR_PRICE = 'GET_CAR_PRICE ';
export const GET_CAR_INSTALLMENT = 'GET_CAR_INSTALLMENT';
export const GET_DEALERS = 'GET_DEALERS';
export const SELECT_PROMOTIONS = 'SELECT_PROMOTIONS';
export const REMOVE_PROMOTIONS = 'REMOVE_PROMOTIONS';

export function getCarModel(payload) {
  return {
    type: GET_CAR_MODEL,
    payload
  };
}

export function getCarParams(payload) {
  return dispatch => {
    dispatch(fetchCarParamsSuccess(payload));
  };
}

export function fetchCarParamsSuccess(payload) {
  return {
    type: FETCH_CAR_PARAMS_SUCCESS,
    payload
  };
}

export function fetchCarParamsError(payload) {
  handleError(payload);

  return {
    type: FETCH_CAR_PARAMS_ERROR,
    payload
  };
}

export function clearParams() {
  return {
    type: CLEAR_PARAMS
  };
}

export function clearParam(payload) {
  return {
    type: CLEAR_PARAM,
    payload
  };
}

export function selectedParam(payload) {
  return {
    type: GET_SELECTED_PARAM,
    payload
  };
}

export function getPrice(payload) {
  return {
    type: GET_CAR_PRICE,
    payload
  };
}

export function getInstallment(payload) {
  return {
    type: GET_CAR_INSTALLMENT,
    payload
  };
}

export function getDealers(payload) {
  return {
    type: GET_DEALERS,
    payload
  };
}

export function selectPromotions(payload) {
  const { hasPromotion } = payload;
  if (!hasPromotion) {
    return {
      type: REMOVE_PROMOTIONS,
      payload
    };
  }
  return {
    type: SELECT_PROMOTIONS,
    payload
  };
}

/* PRODUCT */

export const FETCH_CAR_DETAILS_SUCCESS = 'FETCH_CAR_DETAILS_SUCCESS';
export const FETCH_CAR_DETAILS_ERROR = 'FETCH_CAR_DETAILS_ERROR';
export const FETCH_360_IMAGES = 'FETCH_360_IMAGES';
export const FETCH_DEALERS = 'FETCH_DEALERS';
export const FETCH_PROVINCES = 'FETCH_PROVINCES';
export const FETCH_LEADID = 'FETCH_LEADID';

export function fetchCarDetailsSuccess(payload) {
  return {
    type: FETCH_CAR_DETAILS_SUCCESS,
    payload
  };
}

export function fetchCarDetailsError(payload) {
  handleError(payload);

  return {
    type: FETCH_CAR_DETAILS_ERROR,
    payload
  };
}

export function fetch360Images(payload) {
  return dispatch => {
    return new Promise(resolve => {
      dispatch({
        type: FETCH_360_IMAGES,
        payload
      });
      resolve();
    });
  };
}

export function fetchDealers() {
  return (dispatch, getState, { axiosInstances }) => {
    return axiosInstances
      .getDealers()
      .then(res => {
        const { status, data } = res;
        if (status === 200) {
          dispatch({
            type: FETCH_DEALERS,
            payload: data
          });
        } else {
          throw new Error(res.data.ErrorMessage);
        }
        return data;
      })

      .catch(err => {
        handleError(err);
      });
  };
}

export function fetchProvinces() {
  return (dispatch, getState, { axiosInstances }) => {
    return axiosInstances
      .getProvinces()
      .then(res => {
        const { status, data } = res;
        if (status === 200) {
          dispatch({
            type: FETCH_PROVINCES,
            payload: data
          });
        } else {
          throw new Error(res.data.ErrorMessage);
        }
        return data;
      })

      .catch(err => {
        handleError(err);
      });
  };
}

/* FORM */

export const SEND_LEAD_DATA = 'SEND_LEAD_DATA';
export function sendLeadData(data) {
  return (dispatch, getState, { axiosInstances, history }) => {
    return axiosInstances
      .sendCarInterestLead(data)
      .then(res => {
        if (res.data.success) {
          dispatch({
            type: FETCH_LEADID,
            payload: res.data.leadId || ''
          });
        } else {
          handleError(new Error('Connection error'));
        }
      })
      .catch(err => {
        handleError(err);
      });
  };
}
