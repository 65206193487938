import React, { Component, lazy } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import cx from 'classnames';
import flow from 'lodash/flow';
import styles from './search-box.jss';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { Box } from "@material-ui/core";


const SearchBox = lazy(() => import('./search-box.js'));
const ActionButtons = lazy(() => import('./action-buttons/action-buttons'));

export class SearchBoxContainer extends Component {
  static displayName = 'SearchBoxContainer';

  static propTypes = {
    carParams: PropTypes.object
  };

  static defaultProps = {
    classes: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      clearParams: false,
      clearInstallment: false,
      carParams: this.props.carSalon.carParams,
      disableSearchBox: false
    };
  }

  clearParams = () => {
    this.setState({ clearParams: !this.state.clearParams });
  }

  componentDidMount() {
    const { searchData, defaultData, carParams } = this.props.carSalon;
    if (!isEmpty(carParams)) {
      this.props.actions.selectedParam(Object.keys(carParams).find(item => item[0]));
      this.setState({ currentParams: defaultData, otherParams: searchData });
    } else if (isEmpty(carParams)) {
      this.setState({
        currentParams: searchData
      });
    }
  }

  componentDidUpdate = prevProps => {
    const { carParams, carModel } = this.props.carSalon;
    if (!isEqual(carParams, prevProps.carSalon.carParams) && !isEmpty(carParams)) {
      this.setState({ disableSearchBox: true });
      this.props.actions.fetchSearchData(carParams).then(res => {
        this.setState({ otherParams: res, disableSearchBox: false });
      });
    } else if (!isEqual(carParams, prevProps.carSalon.carParams) && isEmpty(carParams)) {
      this.setState({ disableSearchBox: true });
      this.props.actions.fetchSearchData(carParams).then(res => {
        this.setState({ currentParams: res, otherParams: res, clearInstallment: !this.state.clearInstallment, disableSearchBox: false });
      });
    } else if (!isEqual(carModel, prevProps.carSalon.carModel)) {
      this.setState({ disableSearchBox: true });
      this.props.actions.fetchSearchData().then(res => {
        this.setState({ currentParams: res, otherParams: res, disableSearchBox: false });
      });
    }
  }

  render = () => {
    const { classes, actions, carSalon, paramsMap, handleBannerChange, handleMetaChange, history } = this.props;
    const { currentParams, otherParams, clearParams, disableSearchBox, clearInstallment } = this.state;


    return (
      <div name="searchbox" id="searchbox" className={cx(classes.container, { [classes.hidden]: disableSearchBox })}>
        <div className={cx(classes.bgWrapper)}>
          {carSalon.carData ? <SearchBox
            history={history}
            currentParams={currentParams}
            otherParams={otherParams}
            actions={actions}
            carSalon={carSalon}
            clearParamsState={clearParams}
            clearInstallment={clearInstallment} /> : <Box sx={{ minHegiht: '623px', backgroundColor: '#F9F9FB' }} />}
          <ActionButtons
            handleMetaChange={handleMetaChange}
            handleClearParams={this.clearParams}
            handleBannerChange={handleBannerChange}
            actions={actions}
            history={history}
            carSalon={carSalon}
            paramsMap={paramsMap} />
        </div>
      </div>
    );
  };
}

export default flow([withStyles(styles)])(SearchBoxContainer);
