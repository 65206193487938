import React, { Component, lazy } from 'react';
import PropTypes from 'prop-types';
import flow from 'lodash/flow';
import { withStyles } from '@material-ui/core/styles';
import styles from './section-banner.jss.js';
import { header } from './section-banner.data';
import isObject from 'lodash/isObject';

const BannerSection = lazy(() => import('./section-banner.js'));

export class BannerContainer extends Component {
  static displayName = 'BannerContainer';

  constructor(props) {
    super(props);
    this.state = {
      content: []
    };
  }

  static defaultProps = {
    classes: {}
  };

  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  findBannerData = () => {
    const { carModel, defaultContent, modelContent } = this.props;
    const headerModel = `${carModel} od ręki!`;

    if (carModel && modelContent.length > 0) {
      this.setState({ content: modelContent[0].content, headerTxt: headerModel });
    } else {
      this.setState({ content: defaultContent, headerTxt: header });
    }
  }

  componentDidMount() {
    const { carModel, defaultContent } = this.props;
    if (carModel) {
      this.findBannerData();
    } else {
      this.setState({ content: defaultContent, headerTxt: header });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.onChange !== prevProps.onChange) {
      this.setState({ imageStatus: false }, () => {
        this.findBannerData();
      });
    }
  }

  render = () => {
    const { carModel } = this.props;
    const { headerTxt } = this.state;
    const content = isObject(this.state.content) ? this.state.content : {};

    return (
      <BannerSection
        carModel={carModel}
        headerTxt={headerTxt}
        content={content} />
    );
  };
}

export default flow([withStyles(styles)])(BannerContainer);
