import React, {Component} from 'react';
import {withRouter, Route} from 'react-router-dom';

class ScrollToTopRoute extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.computedMatch.params.vin !== prevProps.computedMatch.params.vin) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {render, ...rest} = this.props;

    return <Route {...rest} render={render} />;
  }
}

export default withRouter(ScrollToTopRoute);
