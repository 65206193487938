import "@makolab/menu-provider/src";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/app';

const { REACT_APP_MENU_PROVIDER } = process.env;

(() => {
  const headerEl = document.getElementById('mlb-one-header');

  if (!headerEl) {
    console.warn("Header #mlb-one-header element not found, skipping");
    return;
  }

  try {
    ReactDOM.render(
      <one-header
        brand="dacia"
        token="DaciaPLOne"
        api={REACT_APP_MENU_PROVIDER}
      />, headerEl);
  } catch (e) {
    console.error(e);
  }
})();

ReactDOM.render(<App />, document.getElementById('root'));

(() => {
  const footerEl = document.getElementById('mlb-one-footer');

  if (!footerEl) {
    console.warn("Footer #mlb-one-footer element not found, skipping");
    return;
  }

  try {
    ReactDOM.render(
      <one-footer
        brand="dacia"
        token="DaciaPLOne"
        api={REACT_APP_MENU_PROVIDER}
      />, footerEl);
  } catch (e) {
    console.error(e);
  }
})();
