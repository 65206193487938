import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import flow from 'lodash/flow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styles from './product-page.jss.js';
import { carEquipmentLabel, carTechnicalInfoLabel, carTechnicalInfoLabelHighlighted, financeLabel, financeLabelHighlighted } from './product-page.data.js';

const Rotate360 = lazy(() => import('../../rotate360/rotate360.container.js'));
const TabsContent = lazy(() => import('./tabs-content/tabs-content.js'));
const CarDetails = lazy(() => import('./car-details/car-details.js'));
const RCIFinancing = lazy(() => import('../../RCIFinancing/RCIFinancing.js'));
const SimpleOffer = lazy(() => import('../../RCIFinancing/SimpleOffer/SimpleOffer.js'));

export class ProductPage extends Component {
  static displayName = 'ProductPage';

  static propTypes = {
    classes: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 1,
      visibleTab: true
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange = (event, newValue) => {
    if (this.state.selectedTab === newValue) {
      this.setState({ visibleTab: !this.state.visibleTab });
      return;
    }
    this.setState({ selectedTab: newValue, visibleTab: true });
  };

  renderCarDetails = item => {
    const { classes, carDetails } = this.props;
    const itemName = item.name === 'price' ? carDetails[item.name].toLocaleString() : carDetails[item.name];

    return (
      <li key={item.label} className={cx(classes.carDetailsListItem)}>
        <Typography align="left" variant="body1">{item.label}</Typography>
        <span className={cx(classes.carDetailsListBorder)} />
        <Typography align="right" variant="body2" className={cx(item.name === 'price' && classes.carDetailsPrice, item.name === 'description' && classes.carDetailsDesc)}>{itemName} {item.name === 'price' && `PLN`}</Typography>
      </li>
    );
  }

  render() {
    const { classes, images360, imagesError, carDetails, imageUrl, visualVCD, modelDatabaseId, RCIready } = this.props;
    const { selectedTab, visibleTab } = this.state;

    return (
      <Grid container className={cx(classes.root)}>
        <Grid item xs={12} md={8} className={cx(classes.rotateWrapper)}>
          <Suspense fallback={<></>}><Rotate360
            imagesError={imagesError}
            modelDatabaseId={modelDatabaseId}
            visualVCD={visualVCD}
            modelName={carDetails.modelName}
            images360={images360}
            imageUrl={imageUrl} /></Suspense>
        </Grid>

        <Grid item xs={12} md={4} className={cx(classes.detailsWrapper)}>
          <Suspense fallback={<></>}><CarDetails
            carDetails={carDetails} /></Suspense>
        </Grid>

        <Grid item xs={12} className={cx(classes.caluclatorWrapper)}>
          <Typography variant="h3" className={cx(classes.caluclatorHeadline)}>{financeLabel} <span className={cx(classes.calculatorHeadlineHighlighted)}>{financeLabelHighlighted}</span></Typography>
          <Suspense fallback={<></>}>{RCIready && <RCIFinancing apiUrl="prod.rciservices.eu" financeParams={[carDetails.rciData]}>
            {({ isOfferFetching, offer, isSimulatorReady, initSimulator }) => (
              <SimpleOffer isOfferFetching={isOfferFetching} offer={offer} isSimulatorReady={isSimulatorReady} initSimulator={initSimulator} />
            )}
          </RCIFinancing>}</Suspense>
        </Grid>

        <Grid item xs={12} className={cx(classes.tabsWrapper)}>
          <Suspense fallback={<></>}><TabsContent
            visible={visibleTab}
            label={[carEquipmentLabel, carTechnicalInfoLabel]}
            labelHighlighted={carTechnicalInfoLabelHighlighted}
            value={selectedTab}
            onChange={this.handleTabChange}
            groups={[carDetails.carEquipmentGroups, carDetails.carTechnicalInfoGroups]}
          /></Suspense>
        </Grid>
      </Grid>
    );
  }
}

export default flow([
  withStyles(styles)
])(ProductPage);
