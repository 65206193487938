
import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { globalTheme } from './global.jss';
import { publicUrl } from '../commons/uri';

const breakpoints = createBreakpoints({});

// https://material-ui.com/customization/themes/

const palette = {
  background: {
    light: '#FFFFFF',
    gray: '#F2F2F2',
    label: '#FF671B',
    icon: '#153450'
  },
  primary: {
    main: '#646b52',
    contrastText: '#ffffff',
    bodyText: '#000000'
  },
  secondary: {
    main: '#000',
    contrastText: '#ffffff'
  },
  neutral: {
    light: '#f2f2f2',
    medium: '#cccccc',
    base: '#777777',
    dark: '#333333'
  }
};

const themeConfig = createTheme({
  palette,
  breakpoints: {
    values: {
      xs: 340,
      sm: 670,
      md: 1024,
      lg: 1240,
      xl: 1920
    }
  },
  spacing: 8,
  typography: {
    fontSize: 14,
    useNextVariants: true,
    fontFamily: ['Block', 'Arial'].join(','),
    h1: {
      fontFamily: 'Block',
      fontWeight: 700,
      color: palette.primary.contrastText,
      fontSize: '2.571em', // 36px
      textTransform: 'uppercase',
      [breakpoints.up('md')]: {
        fontSize: '3.85em' // 54px
      }
    },
    h2: {
      fontFamily: 'Block',
      fontWeight: 700,
      color: palette.primary.main,
      fontSize: '2em', // 28px,
      textTransform: 'uppercase'
    },
    h3: {
      fontFamily: 'Block',
      fontWeight: 700,
      color: palette.primary.main,
      fontSize: '1.714em', // 24px
      textTransform: 'uppercase'
    },
    h4: {
      fontFamily: 'Block',
      fontWeight: 'inherit'
    },
    h5: {
      fontFamily: 'Block',
      fontWeight: 'inherit'
    },
    h6: {
      fontFamily: 'Block',
      fontWeight: 700,
      color: palette.primary.main,
      lineHeight: '26px',
      fontSize: '1.429em' // 20px
    },
    subtitle1: {
      fontFamily: 'Block', // Normal
      fontWeight: 500,
      lineHeight: '28px',
      color: palette.primary.main,
      fontSize: 'inherit'
    },
    subtitle2: {
      fontFamily: 'Block', // Light
      fontWeight: 400,
      lineHeight: '28px',
      color: palette.primary.main,
      fontSize: 'inherit'
    },
    body1: {
      fontFamily: 'Block',
      fontWeight: 700,
      lineHeight: '24px',
      color: palette.primary.bodyText,
      fontSize: 'inherit'
    },
    body2: {
      fontFamily: 'Block',
      fontWeight: 500,
      lineHeight: '24px',
      color: palette.primary.bodyText,
      fontSize: 'inherit'
    }
  },
  mlbCustomVariables: { // put your custom project theme variables here
    svgIconUrl: publicUrl('/static/svg/dacia-one-icons.svg'), // theme.mlbCustomVariables.svgIconUrl
    publicUrl
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '0px',
        minWidth: '150px',
        height: '46px',
        boxShadow: 'none'
      },
      label: {
        fontSize: '.9em',
        fontWeight: 700,
        textTransform: 'uppercase'
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none'
        },
        '&:hover': {
          boxShadow: 'none'
        }
      },
      outlined: {
        border: '2px solid'
      },
      outlinedPrimary: {
        border: '2px solid',
        borderColor: palette.primary.main,
        '@media (hover: none)': {
          '&:hover': {
            border: '2px solid',
            borderColor: palette.secondary.main,
            color: palette.primary.main
          }
        },
        '&:hover': {
          border: '2px solid',
          borderColor: palette.secondary.main,
          backgroundColor: palette.secondary.main,
          color: palette.primary.contrastText
        }
      },
      outlinedSecondary: {
        border: '2px solid',
        borderColor: palette.primary.contrastText,
        color: palette.primary.contrastText,
        '@media (hover: none)': {
          '&:hover': {
            border: '2px solid',
            borderColor: palette.primary.contrastText,
            color: palette.primary.contrastText
          }
        },
        '&:hover': {
          border: '2px solid',
          borderColor: palette.primary.contrastText,
          backgroundColor: palette.primary.contrastText,
          color: palette.primary.main
        }
      }
    },
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: 0,
          marginLeft: 8
        }
      },
      underline: {
        '&&&&:hover:before': {
          borderBottom: '0px'
        },
        '&:before': {
          borderBottom: '0px'
        },
        '&:after': {
          borderBottom: `0px`
        }
      }
    },
    MuiInputLabel: {
      shrink: {
        display: 'none'
      },
      formControl: {
        color: palette.primary.bodyText,
        fontSize: '1em',
        marginLeft: '32px',
        padding: '17px 8px',
        transform: 'none'
      }
    },
    MuiInputBase: {
      root: {
        fontWeight: 500
      }
    },
    MuiFormLabel: {
      root: {
        fontWeight: 500,
        '&$focused': {
          color: '#000'
        }
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: '31px'
      }
    },
    MuiTab: {
      root: {
        fontSize: '1.143em',
        [breakpoints.down('xs')]: {
          width: '100%',
          maxWidth: '100%'
        },
        [breakpoints.up('xs')]: {
          fontSize: '1.143em'
        }
      },
      fullWidth: {
        flexGrow: 0
      },
      labelContainer: {
        [breakpoints.down('sm')]: {
          padding: 0
        },
        [breakpoints.up('sm')]: {
          padding: '6px 8px'
        }
      },
      textColorInherit: {
        opacity: '1'
      }
    },
    MuiTabs: {
      flexContainer: {
        justifyContent: 'center'
      }
    },
    MuiPrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: 'transparent'
      }
    },
    MuiMenuItem: {
      root: {
        fontWeight: 500
      }
    },
    MuiCssBaseline: {
      ...globalTheme
    }
  }
});

export default themeConfig;
