import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import flow from 'lodash/flow';
import isEqual from 'lodash/isEqual';
import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Grid from '@material-ui/core/Grid';
import {
  API_BASE_URL,
  GET_CARS,
  GET_360_EXTERNAL,
  GET_IMAGE_URL
} from '../../../commons/uri';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import styles from './product-page.jss.js';
import withVirtualSalonState from '../../../commons/with-virtual-salon-state';
import { backButton } from './product-page.data.js';
import { Loading } from '../../_shared/loading/loading';
import ProductPage from './product-page';

const FormPage = lazy(() => import('./form-page/form-page'));
const MarketingText = lazy(() => import('../../marketing-text/marketing-text.js'));

export class ProductPageContainer extends Component {
  static displayName = 'ProductPageContainer';

  static propTypes = {
    classes: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      RCIready: false
    };
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    if (this.props.history.length > 2) {
      this.props.history.goBack();
    } else {
      this.props.history.push('/');
      window.scrollTo(0, 0);
    }
  }

  async componentDidMount() {
    const res = await axios.get(
      `${API_BASE_URL}${GET_CARS}/${this.props.match.params.vin}`, { validateStatus: () => true }
    );

    if (res.status === 200) {
      this.props.actions.fetchCarDetailsSuccess(res.data);
      this.setState({ loading: false });
      const images = await axios.get(
        `${GET_360_EXTERNAL}/${this.props.match.params.vin}`, { validateStatus: () => true }
      );
      if (images.status === 200) {
        this.props.actions.fetch360Images(images.data).then(() => {
          const scene = Object.values(images.data)[0];
          const params = new URL(scene);
          const databaseId = params.searchParams.get('databaseId');
          const configuration = params.searchParams.get('configuration');
          this.setState({ visualVCD: configuration, modelDatabaseId: databaseId });
        });
      } else {
        this.setState({ imagesError: true });
      }
    } else if (res.status !== 200) {
      this.props.history.push('/');
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.carSalon.carDetails, prevProps.carSalon.carDetails)
    ) {
      this.setState({ loading: true });
    }
  }

  makeImageUrl = (code, vin) => {
    return `${API_BASE_URL}${GET_IMAGE_URL}?code=${code}&vin=${vin}`;
  };

  handleScriptInject({ scriptTags }) {
    if (scriptTags) {
      const scriptTag = scriptTags[0];
      scriptTag.onload = this.handleOnLoad;
    }
  }

  handleOnLoad = () => {
    this.setState({ RCIready: true });
  }

  render() {
    const {
      classes,
      history,
      actions: { sendLeadData, fetchSearchData },
      carSalon: { defaultData, carDetails, images360, searchData }
    } = this.props;
    const { visualVCD, modelDatabaseId, imagesError, loading } = this.state;
    const imageUrl =
      carDetails && this.makeImageUrl(carDetails.modelCode, carDetails.vin);

    const metaTitle = carDetails && `Kup ${carDetails.modelName} ${carDetails?.version}, ${carDetails.fuel} kolor ${carDetails.color} | ${carDetails?.dealerName} | Dacia Polska`;
    const metaDesc = carDetails && `Chcesz kupić samochód ${carDetails.modelName} ${carDetails?.version}, ${carDetails.fuel} kolor ${carDetails.color}? Kliknij by poznać szczegóły oferty lub wybierz inne dostępne samochody u naszego autoryzowanego dealera ${carDetails?.dealerName}.`;
    const canonical = window.location.origin + window.location.pathname;

    if (loading) {
      return <Loading />;
    }

    return (
      <Grid container className={cx(classes.root)}>
        <Helmet
          onChangeClientState={(newState, addedTags) => this.handleScriptInject(addedTags)}
          title={metaTitle}
          meta={[
            {
              name: 'description',
              content: metaDesc
            }
          ]}>
           <script src="//prod.rciservices.eu/proxy/js/latest/RCIFinancialProxy.js" type="text/javascript" />
           <link rel="canonical" href={canonical} />
           </Helmet>
        <Grid item xs={12} className={cx(classes.goBackWrapper)}>
          <Button
            variant="text"
            className={cx(classes.goBackButton)}
            onClick={this.goBack}
          >
            <ChevronLeft />  {backButton}
          </Button>
        </Grid>
        <ProductPage
          carDetails={carDetails}
          images360={images360}
          imageUrl={imageUrl}
          visualVCD={visualVCD}
          modelDatabaseId={modelDatabaseId}
          imagesError={imagesError}
          RCIready={this.state.RCIready}
        />
        <Suspense fallback={<></>}><FormPage
          history={history}
          sendLeadData={sendLeadData}
          modelName={carDetails.modelName}
          version={carDetails.version}
          modelCode={carDetails.modelCode}
          vin={carDetails.vin}
          fetchSearchData={fetchSearchData}
          dealers={searchData?.dealers}
          isFromDealer={carDetails?.isFromDealer}
        /></Suspense>
        <Suspense fallback={<></>}><MarketingText
          marketingText={defaultData.marketingText}
        /></Suspense>
      </Grid>
    );
  }
}

export default flow([withVirtualSalonState(), withStyles(styles)])(
  ProductPageContainer
);
