export default theme => ({
  container: {
    padding: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8)
    }
  },
  titleWrapper: {
    padding: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(2)
  }
});
