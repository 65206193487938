export default theme => ({
  container: {
    width: '100%'
  },
  image: {
    width: '100%',
    height: 'auto',
    position: 'relative'
  },
  wrapper: {
    position: 'relative',
    bottom: '0',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      bottom: '112px',
      marginBottom: `-${theme.spacing(12)}`
    },
    [theme.breakpoints.up('md')]: {
      bottom: '150px',
      marginBottom: `-${theme.spacing(12)}`
    }
  }
});
