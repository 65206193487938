import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import * as axiosInstances from './commons/axios-instances';
import combineReducers from './reducers';
import {createBrowserHistory} from 'history';
import {HTML_BASE_NAME} from './commons/constants';
import {routerMiddleware} from 'connected-react-router';

export const history = createBrowserHistory({
  basename: HTML_BASE_NAME
});

export default createStore(
  combineReducers(history),
  compose(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware.withExtraArgument({
        axiosInstances,
        history
      })
    ),
    ...(window.__REDUX_DEVTOOLS_EXTENSION__ ? [window.__REDUX_DEVTOOLS_EXTENSION__()] : [])
  )
);
