import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import flow from 'lodash/flow';

import actions from '../actions/actions-only';

export default () => WrappedComponent => {
  class WithDataService extends Component {
    static displayName = `WithDataService(${WrappedComponent.displayName})`;

    render = () => {
      return <WrappedComponent {...this.props} />;
    };
  }

  const mapStateToProps = carSalon => (carSalon);

  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      {
        ...actions
      },
      dispatch
    )
  });

  return flow([
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  ])(WithDataService);
};
