import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const ErrorMessageComponent = ({ classes, children }) => (
  <Grid container spacing={3} className={classes.root}>
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography className={classes.error} variant="h5">
          ERROR: {children}
        </Typography>
        <Typography variant="body1">
          Please contact website administrator.
        </Typography>
      </Paper>
    </Grid>
  </Grid>
);

const ErrorComponent = withStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 'bold'
  }
}))(ErrorMessageComponent);

// eslint-disable-next-line import/no-anonymous-default-export
export default (error, render = false) => {
  const { message } = error;

  console.error(error);


  if (render === true) {
    return (
      <ErrorComponent>
        {message}
      </ErrorComponent>
    );
  }

  return error;
};
