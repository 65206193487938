export const paramsMap = [
  {slug: 'kolor', name: 'color', param: 'colors'},
  {slug: 'silnik', name: 'fuel', param: 'fuels'},
  {slug: 'skrzynia', name: 'gearbox', param: 'gearBoxes'},
  {slug: 'rataMax', name: 'installmentMax', param: 'installments'},
  {slug: 'rataMin', name: 'installmentMin', param: 'installments'},
  {slug: 'cenaMax', name: 'priceMax', param: 'prices'},
  {slug: 'cenaMin', name: 'priceMin', param: 'prices'},
  {slug: 'wersja', name: 'version', param: 'versions'},
  {slug: 'rocznik', name: 'year', param: 'years'},
  {slug: 'promocja', name: 'promotions', param: 'promotions'},
  {slug: 'promocje', name: 'hasPromotion', param: 'hasPromotion'},
  {slug: 'wojewodztwo', name: 'province', param: 'provinces'},
  {slug: 'dealers', name: 'dealerId', param: 'dealers'},
  {slug: 'place', name: 'place', param: 'place'}
];

export const metaTitle = 'Nowe auta osobowe od ręki | Auta online | Wirtualny salon Dacia';
export const metaDesc = 'Wejdź na wirtualny salon Dacia i poznaj modele wygodnych samochodów dostępnych od ręki. Kup auto marki Dacia z salonu online – bez wychodzenia z domu!';
