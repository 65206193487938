import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import cx from 'classnames';
import flow from 'lodash/flow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Helmet} from 'react-helmet';

import Button from '../_shared/button/button';
import styles from './thank-you-page.jss';
import {buttons} from './thank-you-page.btn.data';
import {sendForm, leadFormValidation} from '../../commons/gtm-events';

export class ThankYouPage extends Component {
  static displayName = 'ThankYouPage';

  static propTypes = {
    classes: PropTypes.object
  };

  takeAction = url => () => {
    window.location.href = url;
  };

  componentDidMount() {
    const dealerName = (this.props.carSalon.carDetails === null) ? '' : this.props.carSalon.carDetails.dealerName;
    const dealerId = (this.props.carSalon.carDetails === null) ? '' : this.props.carSalon.carDetails.dealerId;
    const modelName = (this.props.carSalon.carDetails === null) ? '' : this.props.carSalon.carDetails.modelName;
    const modelId = (this.props.carSalon.carDetails === null) ? '' : this.props.carSalon.carDetails.modelCode;
    const leadId = (this.props.carSalon.leadId === null) ? '' : this.props.carSalon.leadId;
    sendForm();
    leadFormValidation(dealerName, dealerId, modelName, modelId, leadId);
  }

  render() {
    const {classes, title} = this.props;

    return (
      <Grid
        className={cx(classes.container)}
        container
        justifyContent="center"
        direction="column"
        align="center"
      >
        <Helmet
          title={title} />

        <Grid
          item
          className={cx(classes.titleWrapper)}
        >
          <Typography
            variant="h3"
            color="primary"
            align="center"
            gutterBottom
          >
            <span>Formularz został wysłany</span>
          </Typography>
          <Typography
            variant="body1"
            align="center"
          >
            <span>Dziękujemy! Czekając na odpowiedź</span>
          </Typography>
        </Grid>

        <Grid
          container
          spacing={8}
          justifyContent="center"
          className={cx(classes.btnWrapper)}
        >
          {buttons.map(({name, url, color, variant}) => (
            <Grid key={name} item><Button
              label={name}
              variant={variant}
              color={color}
              onClick={this.takeAction(url)}
            /></Grid>
          ))}
        </Grid>
      </Grid>
    );
  }
}

export default flow([withStyles(styles)])(ThankYouPage);
