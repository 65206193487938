import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import cx from 'classnames';
import flow from 'lodash/flow';
import {Helmet} from 'react-helmet';

import styles from './not-found.jss.js';

import NotFound from './not-found.js';

export class NotFoundContainer extends Component {
  static displayName = 'NotFoundContainer';

  static propTypes = {
    classes: PropTypes.object
  };

  static defaultProps = {
    classes: {}
  };

  render = () => {
    const {classes, title} = this.props;

    return (
      <div className={cx(classes.container)}>
        <Helmet
          title={title} />
        <NotFound />
      </div>
    );
  };
}

export default flow([withStyles(styles)])(
  NotFoundContainer
);
