export default theme => ({
  container: {
    maxWidth: '1280px',
    margin: '0 auto'
  },
  hidden: {
    pointerEvents: 'none'
  },
  bgWrapper: {
    backgroundColor: theme.palette.background.gray,
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10)
    }
  }
});
