/* eslint-disable compat/compat */
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import flow from 'lodash/flow';
import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import styles from './starter-page.jss.js';

import Grid from '@material-ui/core/Grid';
import withVirtualSalonState from '../../commons/with-virtual-salon-state';
import { Loading } from '../_shared/loading/loading.js';
import { metaDesc, metaTitle, paramsMap } from './starter-page.data.js';
import MainPage from './section-main-page/main-page.container.js';
import NotFound from '../not-found/not-found.container.js';
import ScrollToTopRoute from './router-scroll-to-top/router-scroll-to-top.js';
import ThankYouPage from '../thank-you-page/thank-you-page.js';
import ProductPage from './section-product-page/product-page-container.js';

export class StarterPageContainer extends PureComponent {
  static displayName = 'StarterPageContainer';

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  async findParams(params) {
    const { defaultData } = this.props.carSalon;
    const validateParams = Object.entries(params).map(value => {
      const parVal = paramsMap.find(item => item.name === value[0]).param;

      if (parVal === 'hasPromotion') {
        return { ...params, hasPromotion: true };
      }

      if (parVal === 'dealers') {
        const { dealerId } = params;
        const dealers = dealerId.split(',');
        const dealersIds = dealers.map((item) => Number(item));
        return { ...params, dealerId: dealersIds };
      }

      if (parVal === 'wojewodztwo') {
        return params;
      }

      if (parVal === 'promotions') {
        if (defaultData[value[0]].find(item => item.id === value[1])) {
          return { ...params, hasPromotion: true };
        }
        return this.props.history.push('/');
      }

      if (parVal === 'versions') {
        return params;
      }

      if (parVal === 'years') {
        if (defaultData[parVal].find(item => item.toString() === value[1].toString())) {
          return params;
        }
      }

      if (parVal === 'place') {
        const [lat, long] = Object.values(params)[0].split(',');
        return { ...params, place: { latitude: lat, longitude: long } };
      }

      if (parVal !== 'installments' && parVal !== 'prices' && parVal !== 'years') {
        if (defaultData[parVal].find(item => item === value[1])) {
          return params;
        }
      }

      return params;
    });
    return validateParams;
  }

  async renameParams(params) {
    const par = params.split('&');
    let parObj = [];
    par.forEach(value => {
      const parVal = value.split('=');
      if (paramsMap.every(item => item.slug !== parVal[0])) {
        return;
      }
      const parName = paramsMap.find(item => item.slug === parVal[0]).name;
      parObj = ({ ...parObj, [parName]: decodeURI(parVal[1]) });
    });
    return parObj;
  }

  async componentDidMount() {
    try {
      const defaultData = await this.props.actions.fetchDefaultData();
      if (defaultData) {
        if (this.props.router.location.search) {
          const getParamsName = await this.renameParams(this.props.router.location.search.substring(1));
          if (getParamsName) {
            const validateParams = await this.findParams(getParamsName);
            this.props.actions.getCarParams(validateParams[0]);
            const queryParams = new URLSearchParams(this.props.history.location.search);
            const pageNumber = queryParams.get('page');
            if (pageNumber) this.props.actions.changePageNumber(pageNumber)
            this.setState({ loading: false });
          }
        } else {
          this.setState({ loading: false });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  render = () => {
    const { classes, carSalon, actions } = this.props;
    const { loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <Grid container className={cx(classes.root)}>
        <div className="grey-toolbar">
          <h1 className="grey-toolbar-title">Witaj na Dacia.pl</h1>
        </div>
        <Switch>
          <ScrollToTopRoute exact path="/" render={props => (<MainPage {...props} carSalon={carSalon} actions={actions} paramsMap={paramsMap} title={metaTitle} pageDescription={metaDesc} />)} />
          <ScrollToTopRoute exact path="/gama/:model" render={props => (<MainPage {...props} carSalon={carSalon} actions={actions} paramsMap={paramsMap} />)} />
          <ScrollToTopRoute exact path="/gama/:model/:vin" render={props => (<ProductPage {...props} />)} />
          <Route exact path="/formularz-wyslany" render={props => (<ThankYouPage {...props} carSalon={carSalon} title={'Dziękujemy za wysłanie formularza | Wirtualny salon Dacia'} />)} />
          <Route render={props => (<NotFound {...props} title={'Strony nie odnaleziono | Wirtualny salon Dacia'} />)} />
        </Switch>
      </Grid>
    );
  };
}

export default flow([withVirtualSalonState(), withStyles(styles)])(StarterPageContainer);
