import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import flow from 'lodash/flow';
import startCase from 'lodash/startCase';
import { Helmet } from 'react-helmet';
import styles from './main-page.jss.js';
import { metaTitle, metaDesc } from '../starter-page.data.js';
import MainPage from './main-page.js';

export class MainPageContainer extends Component {
  static displayName = 'MainPageContainer';

  static propTypes = {
    classes: PropTypes.object,
    carSalon: PropTypes.object.isRequired,
    fetchCarData: PropTypes.func,
    fetchSearchData: PropTypes.func
  };

  static defaultProps = {
    classes: {}
  };

  state = {
    currentTitle: this.props.title,
    currentDesc: this.props.pageDescription
  };

  async componentDidMount() {
    const { match } = this.props;
    const { mapLinks } = this.props.carSalon;
    const {
      getCarModel,
      fetchCarData,
      fetchSearchData
    } = this.props.actions;

    try {
      if (match.params.model) {
        if (mapLinks.find(item => item.slug === match.params.model)) {
          const modelParam = mapLinks.find(
            item => item.slug === match.params.model
          ).name;
          getCarModel(modelParam);
        } else {
          this.props.history.push('/');
        }
      }
      fetchSearchData();
      fetchCarData();
      this.handleMetaChange(match.params.model);
    } catch (e) {
      console.log(e);
    }
  }

  handleMetaChange = model => {
    const { carSalon } = this.props;
    const currentModel = carSalon.carModel ? carSalon.carModel : model;
    const queryParams = new URLSearchParams(this.props.history.location.search);
    const currentPageNumber = queryParams.get('page');
    const pageTitleWithNumber = currentPageNumber > 1 ? ` - Strona ${currentPageNumber}` : '';

    if (currentModel) {
      const metaTitleForModel = `Samochód ${startCase(currentModel)} online | Wirtualny salon Dacia${pageTitleWithNumber}`;
      const metaDescForModel = `Poznaj samochód osobowy ${startCase(currentModel)} dostępny online w salonie wirtualnym Dacia! Kup wymarzony model Dacii od ręki z naszego salonu online!${pageTitleWithNumber}`;
      this.setState({ currentTitle: metaTitleForModel, currrentDesc: metaDescForModel });
    } else {
      this.setState({ currentTitle: `${metaTitle}${pageTitleWithNumber}`, currrentDesc: `${metaDesc}${pageTitleWithNumber}` });
    }
  }

  render = () => {
    const { carSalon, paramsMap, actions, history, location } = this.props;
    const canonical = window.location.origin + window.location.pathname;
    const pageParam = new URL(window.location.href).searchParams.get('page');

    return (
      <React.Fragment>
        <Helmet
          title={this.state.currentTitle}
          meta={[
            {
              name: 'description',
              content: this.state.currrentDesc
            }
          ]}>
          <link rel="canonical" href={pageParam ? canonical + `?page=${pageParam}` : canonical} />
          </Helmet>
        <MainPage
          handleMetaChange={this.handleMetaChange}
          paramsMap={paramsMap}
          history={history}
          location={location}
          carSalon={carSalon}
          actions={actions} />
      </React.Fragment>
    );
  };
}

export default flow([withStyles(styles)])(
  MainPageContainer
);
