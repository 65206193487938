/* eslint-disable complexity */
import * as actionsAndTypes from '../actions';
import merge from 'lodash/merge';

const initialState = {
  dealers: null,
  provinces: null,
  images360: null,
  carData: null,
  carDetails: null,
  carModel: null,
  carParams: {},
  searchData: null,
  defaultData: null,
  selectedParam: null,
  carsPerPage: 20,
  pageNumber: 1,
  sortParams: {
    sort: 'Profit',
    sortDirection: 'asc'
  },
  error: null,
  loading: false,
  leadId: null
};

export default function salonReducer(state = initialState, action) {
  switch (action.type) {
    case actionsAndTypes.GET_LINK_MAP: {
      return {...state, mapLinks: action.payload};
    }

    case actionsAndTypes.GET_CAR_MODEL: {
      return {...state, carModel: action.payload};
    }

    case actionsAndTypes.FETCH_DEFAULT_DATA_SUCCESS: {
      return {...state, loading: true, defaultData: action.payload};
    }

    case actionsAndTypes.FETCH_DEFAULT_DATA_ERROR: {
      return {...state, error: true};
    }

    case actionsAndTypes.FETCH_FILTER_DATA_SUCCESS: {
      return {...state, loading: true, searchData: action.payload};
    }

    case actionsAndTypes.FETCH_FILTER_DATA_ERROR: {
      return {...state, error: true};
    }

    case actionsAndTypes.FETCH_CAR_DATA_SUCCESS: {
      return {...state, carData: action.payload};
    }

    case actionsAndTypes.FETCH_360_IMAGES: {
      return {...state, images360: action.payload};
    }

    case actionsAndTypes.FETCH_DEALERS: {
      return {...state, dealers: action.payload};
    }

    case actionsAndTypes.FETCH_PROVINCES: {
      return {...state, provinces: action.payload};
    }

    case actionsAndTypes.FETCH_CAR_DATA_ERROR: {
      return {...state, error: true};
    }

    case actionsAndTypes.FETCH_CAR_DETAILS_SUCCESS: {
      return {...state,
        carDetails: action.payload
      };
    }

    case actionsAndTypes.FETCH_CAR_PARAMS_SUCCESS: {
      return {...state,
        carParams: {
          ...action.payload}
      };
    }

    case actionsAndTypes.GET_CAR_PRICE: {
      return merge({}, state, {
        carParams: action.payload
      });
    }

    case actionsAndTypes.GET_CAR_INSTALLMENT: {
      return merge({}, state, {
        carParams: action.payload
      });
    }

    case actionsAndTypes.GET_DEALERS: {
      return {...state,
        carParams: {
          ...state.carParams,
          ...action.payload}
      };
    }

    case actionsAndTypes.SELECT_PROMOTIONS: {
      return merge({}, state, {
        carParams: action.payload
      });
    }

    case actionsAndTypes.REMOVE_PROMOTIONS: {
      return {...state,
        carParams: delete state.carParams[action.payload], ...state.carParams
      };
    }

    case actionsAndTypes.GET_SELECTED_PARAM: {
      return {...state, selectedParam: action.payload};
    }

    case actionsAndTypes.CLEAR_PARAMS: {
      return {...state,
        selectedParam: null,
        carParams: {...initialState.carParams}
      };
    }

    case actionsAndTypes.CLEAR_PARAM: {
      const { [action.payload]: value, ...rest } = state.carParams;
       return {...state,
        carParams: {...rest}
      };
    }

    case actionsAndTypes.CHANGE_CARS_PER_PAGE: {
      return {...state, pageNumber: 1, carsPerPage: action.payload};
    }

    case actionsAndTypes.CHANGE_PAGE_NUMBER: {
      return {...state, pageNumber: action.payload};
    }

    case actionsAndTypes.CHANGE_SORT_PARAMS: {
      return {...state, sortParams: action.payload};
    }

    case actionsAndTypes.FETCH_LEADID: {
      return {...state, leadId: action.payload};
    }

    default:
      return state;
  }
}
